import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-symbols",
  "date": "2015-09-14",
  "title": "ES6 SYMBOLS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Symbols are a new primitive type in ES6. If you ask me, they’re an awful lot like strings. They are tokens that serve as unique IDs. They will help avoid name clashes between properties."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why Symbols?`}</h2>
    <p>{`There are two key points to understand about symbols:`}</p>
    <ul>
      <li parentName="ul">{`A symbol is unique and immutable. `}</li>
      <li parentName="ul">{`You can use symbols as identifiers when adding properties to an object. `}</li>
    </ul>
    <Message type="warn" title="Symbols are not private" content="Symbols are not private after all, because you can find them via reflection. Specifically, via the `Object.getOwnPropertySymbols` method and through proxies." mdxType="Message" />
    <h2>{`The Symbol Function`}</h2>
    <p>{`Let’s start by creating a new symbol. Creating a new symbol is as easy as invoking Symbol as a function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var secret = Symbol();
`}</code></pre>
    <p>{`Note that there's no `}<inlineCode parentName="p">{`new`}</inlineCode>{`. The `}<inlineCode parentName="p">{`new`}</inlineCode>{` operator will throw an error if you tried to use it.`}</p>
    <p>{`For debugging purposes, you can give a description to the symbol:`}</p>
    <pre><code parentName="pre" {...{}}>{`var secret = Symbol('this is a description for the secret');
`}</code></pre>
    <p>{`Symbols are `}<inlineCode parentName="p">{`immutable`}</inlineCode>{` like numbers or strings. But they are unique, unlike numbers and strings.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var s1 = Symbol();
var s2 = Symbol();

s1 === s2; // false
`}</code></pre>
    <h2>{`Symbols as keys`}</h2>
    <p>{`You can use symbols as keys into an object. In the following code we create one property using a string ("lastName"), and one with a symbol (the firstName symbol).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var firstName = Symbol();

var person = {
    lastName: "A",
    [firstName]: "Sean",
};
`}</code></pre>
    <p>{`This will help us to create unique keys, where in an object, keys cannot be duplicated.`}</p>
    <Message type="warn" title="etrieving Symbols" content="Keys created using symbols, are not going to appear when using `Object.getOwnPropertyNames`, `for..in` loops, or `JSON.stringify`.
  You will have to use `getOwnPropertySymbols` instead." mdxType="Message" />
    <h2>{`Retrieving Symbols`}</h2>
    <p>{`Let's look at an example where symbols are being retrieved using `}<inlineCode parentName="p">{`Object.getOwnPropertyNames`}</inlineCode>{`: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var firstName = Symbol();

var person = {
    lastName: "A",
    [firstName]: "Sean",
};

var symbol0 = Object.getOwnPropertySymbols(person)[0];
`}</code></pre>
    <h2>{`@@iterator`}</h2>
    <p>{`You can use symbols to make new iterable objects.`}</p>
    <p>{`The iterator property attached to the Symbol function is what’s called a `}<inlineCode parentName="p">{`well-known`}</inlineCode>{` symbol. The following code will check to see if strings, arrays, and numbers are iterable by looking for this well-known iterator method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var sum = 0;
vr numbers = [1,2,3,4];

for(let n of numbers) {
    sum += n;
}

sum === 10; // true
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`for..of`}</inlineCode>{` is an iterator method. Let's have a look what really happens behind the scenes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`...
let iterator = numbers[Symbol.iterator]();
let next = iterator.next();
...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      